import React, { Component } from 'react';
import accounting from 'accounting';
import t from './i18n';
import { Globals, PriWebApp } from './Globals';
import { findDOMNode } from 'react-dom';
import { isNumber } from 'util';
import moment from 'moment';

function pweb(){
    return PriWebApp;
}

function pwebs(){
    return pweb().store;
}

const Utils = {

    getUrlParam: function( index , defaultValue = undefined ){ // positive or negative
        const parts = window.location.pathname.split( '/' );

        if( index < 0 ){
            index = parts.length + index;
            if( index < 0 )
                return defaultValue;
        }

        if( index >= parts.length )
            return defaultValue;

        return decodeURIComponent( parts[index] );
    },

    truncate: function( text , max = 25 , suffix = '...' , exact = false ){
        if( !text ) return '';
        if( !text.length ) return '';

        if( text.length <= max ) return text;

        if( !exact ) 
            return text.substr( 0 , max ) + '...';

        return text;
    },

    formatCurrency: function( value , currency = 'EUR' ){

        if( currency === 'EUR' )
            return accounting.formatMoney( value , '€ ' , 2 , '.' , ',' );

        return value;
    },

    formatPercent: function( value ){
        if( value <= 0 )
            return '-';
        return (100*(1-value)).toFixed(0) + '%';
    }
}

class Loader extends Component {

    render(){
        return <div className="middle-box text-center animated Loader ">
            <i className="fa fa-spinner fa-pulse" style={{fontSize:'40pt'}}></i>
        </div>;
    }
}

function PrimedLogo(props){

    //return <img src={"/logo_colors.png"} className={"img-responsive"} style={{height: '40px', display: 'inline'}} />;

    const lineStyle = {fill:'none',stroke:'black',strokeWidth:4};

    return <svg viewBox="0 0 250 80" {...props}>
        <polyline points="24,63 24,29 48,29 48,53 24,53" style={lineStyle} />
        
        <polyline points="60,55 60,29 86,29" style={lineStyle} />
        
        <polyline points="97,27 97,53 122,53" style={lineStyle} />
        <rect width="4" height="4" x="95" y="19" style={{fill:'red'}} />
        
        <polyline points="133,55 133,29 157,29 157,55" style={lineStyle} />
        <polyline points="145,55 145,40" style={lineStyle} />
        
        <polyline points="195,29 169,29 169,53 195,53" style={lineStyle} />
        <polyline points="195,41 180,41" style={lineStyle} />
        
        <polyline points="229,19 229,53 205,53 205,29 229,29" style={lineStyle} />
    </svg>;
}

class BoxContainer extends Component {
    render(){

        let title = null;

        const tools = this.props.tools ? this.props.tools : false;

        if( this.props.title ){
            title = <div className="ibox-title">
                <h5>{this.props.title} <small></small></h5>

                {tools ? <div className="ibox-tools">
                    {tools}
                </div> : undefined }

                {/* <div className="ibox-tools">
                    <a className="collapse-link">
                        <i className="fa fa-chevron-up"></i>
                    </a>
                    <a className="dropdown-toggle" data-toggle="dropdown" href="#">
                        <i className="fa fa-wrench"></i>
                    </a>
                    <ul className="dropdown-menu dropdown-user">
                        <li><a href="#">Config option 1</a>
                        </li>
                        <li><a href="#">Config option 2</a>
                        </li>
                    </ul>
                    <a className="close-link">
                        <i className="fa fa-times"></i>
                    </a>
                </div> */}

            </div>;
        }

        return <div className="ibox float-e-margins" {...this.props}>
            {title}
            <div className="ibox-content">
                {this.props.children}
            </div>

        </div>;
    }
}

class Route extends Component {
    constructor(props){
        super(props);

        if( !Route._previousRoutes )
            Route._previousRoutes = [];
    }

    componentDidMount(){
        if( !Route._registeredRoutes ){
            Route._registeredRoutes = [];
        }

        Route._registeredRoutes.push( this );

        if( Route )
            if( Route.triggerLocationChange )
                Route.triggerLocationChange();
    }

    static pushLocation( url , pushState = true ){

        console.log( "Pushing new location " + url );

        if( pushState )
            window.history.pushState( {} , 'title' , url );

        if( Route._previousRoutes )
            Route._previousRoutes.map( route => { return route.forceUpdate() } );

        Route._previousRoute = [];

        if( !Route._registeredRoutes )
            Route._registeredRoutes = [];

        for(let i=0;i<Route._registeredRoutes.length;i++){
            if( Route._registeredRoutes[i].matchUrl() ){
                Route._registeredRoutes[i].forceUpdate();
            }
        }

        var hashElement = document.getElementById( window.location.hash.replace( /#/g , '' ) );

        if( hashElement ){
            var rect = hashElement.getBoundingClientRect();

            if( rect )
                window.scrollTo( 0 , rect.top );
        } else {
            window.scrollTo( 0 , 0 );
        }

        if( Route )
            if( Route.triggerLocationChange )
                Route.triggerLocationChange();
    }

    matchUrl(){
        let r = new RegExp( this.props.url );
        let match = r.exec( window.location.pathname );
        if( match )
            return match.splice(1);

        let urlRegexp =  this.props.url.replace( /\{([a-z0-9_]+)\}/gi , "(?<$1>[^\/]+)" );
        match = (new RegExp( urlRegexp ) ).exec( window.location.pathname );
        if( match )
            return match.groups;

        return false;   
    }

    render(){

        const match = this.matchUrl();

        if( !match )
            return null;

        Route._previousRoutes.push( this );

        if( this.props.component ){
            return this.props.component( match );
        }

        return <div>{this.props.children}</div>;
    }
}

Globals.addEvent( Route , 'LocationChange' );

class Link extends Component {
    render(){
        return <a onClick={se => {
            if( this.props.target == '_blank')
                return;
                
            se.preventDefault();
            Route.pushLocation( this.props.href );
        }} {...this.props} >{this.props.children}</a>;
    }
}

function RouteRedirect(props){
    Route.pushLocation(props.url);
    return <span></span>;
}

class FormInputImage extends Component {
    constructor(props){
        super(props);

        this.state = {
            state: 'idle'
        };

        this.fileInput = React.createRef();

        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    onChange(se){
        console.log( this.fileInput.current.files[0] );

        this.setState({ state: 'loading' });

        if( this.props.onUpload ){
            this.props.onUpload( this.fileInput.current.files[0] , () => {
                this.setState({state: 'idle'});
            } );
        }

        pweb().fetch( 'user' , 'uploadLogo' , { logo: this.fileInput.current.files[0] } , ( data) => {
            this.setState({state: 'idle'});
            PriWebApp.store.dispatch( PriWebApp.setUserData( data.data ) );
        });
    }

    onClick(se){

        if( se.target === this.fileInput.current )
            return;

        se.preventDefault();
        if( this.state.state === 'idle' )
            this.fileInput.current.click();
    }

    render(){
        const props = {
            src: false,
            onUploadComplete: () => {},
            ...this.props
        };

        return <div className="FormInputImage" onClick={this.onClick}>

            {this.state.state==='loading' ? (
                <div className="FormInputImagePlaceholder" >
                    <i className="fa fa-sync-alt fa-spin"></i>
                </div>
            ):(
                <div>
                    {props.src ? (<img alt="Logo" src={props.src} width={"100%"} />) : (
                        <div className="FormInputImagePlaceholder" >
                            {this.state.state==='idle' ? (
                                <span>
                                    Carica qui il logo della tua azienda <br/>
                                    <i className="fa fa-upload"></i>
                                </span>
                            ):(<span></span>)}
                        </div>
                    )}

                    <div className="FormInputImageUploadIcon" >
                        <i className="fa fa-upload"></i>
                    </div>

                </div>
            )}

            
            
            <input style={{display: 'none'}} type="file" ref={this.fileInput} onChange={this.onChange} />
        </div>;
    }
}

class ContainerHeader extends Component {

    constructor(props){
        super(props);

        this.state = pweb().store.getState();
    }

    componentDidMount(){
        this.setState( pweb().store.getState() );

        pwebs().dispatch( pweb().hideLoader() );
        pwebs().subscribe( () => { this.setState( pweb().store.getState() )} );

        /*pweb().fetch( 'admin' , 'banners' , 'active' , 'banner' , msg => {

            if( msg.data ){
                if( msg.data.length > 0 ){
                    this.setState({
                        banner: msg.data[0]
                    });
                }
            }
           
        });*/
    }

    render(){

        if( !this.state.breadcrumbsArray.length )
            return null;
            
        let lastLabel = false;
        let count=0;
        let breadcrumbs = this.state.breadcrumbsArray.map( item => {
            lastLabel = item.label;
            if( item.title )
                lastLabel = item.title;
                
            count++;
            const active = count === this.state.breadcrumbsArray.length;
            return <li className={active?'active':''} key={count} ><Link url={item.url}>{item.label}</Link></li>;
        } );

        return <div className="row wrapper border-bottom white-bg page-heading" style={{margin: 0}}>
            <div className="col-md-6">
                <h2>{lastLabel}</h2>
                <ol className="breadcrumb">
                    {breadcrumbs}
                </ol>
            </div>
            <div className="col-md-6" style={{paddingTop: '15px', textAlign: 'right'}}>
                {this.state.banner && <Row>
                    <div className="col-md-1 col-xs-1 hidden-lg">
                        <i className="fa fa-exclamation"
                            style={{fontSize: '30pt',padding: '7px',color: '#9e9e9e'}}></i>
                    </div>
                    <div className="col-md-11 col-xs-11 col-lg-11" style={{margin: "5px;"}}>
                        <h4 style={{maxHeight: '1.2em', overflowY: 'hidden'}}>
                            {this.state.banner.des_titolo}
                        </h4>
                        <p style={{maxHeight: '3em',overflowY: 'hidden',marginBottom: '0'}}>
                            {this.state.banner.des_corpo}
                        </p>
                    </div>
                    <div className="col-lg-1 visible-lg">
                        <i className="fa fa-exclamation" 
                            style={{fontSize: '30pt',padding: '7px',color: '#9e9e9e'}}></i>
                    </div>
                </Row>}
            </div>
        </div>;
    }
}

class NavigationMenuSubItem extends Component {
    constructor(props){
        super(props);

        this.state = { l: window.location.pathname };
    }

    componentDidMount(){
        Route.onLocationChange( () => {
            this.setState({l : window.location.pathname } );
        });
    }

    render(){
        let active = false;
        if( (new RegExp( '^'+this.props.url )).exec( this.state.l ) )
            active = true;
        return <li className={active?'active':''}><Link href={this.props.url} >{this.props.label} {this.props.children}</Link></li>;
    }
}

class NavigationMenuItem extends Component {
    constructor(props){
        super(props);

        this.state = { l: window.location.pathname };
    }

    componentDidMount(){
        Route.onLocationChange( () => {
            this.setState({l : window.location.pathname } );
        });
    }

    render(){
        let child = null;
        let preChild = <Link href={this.props.url} >
            <i className={this.props.fa}></i> 
            <span className="nav-label">{this.props.label}</span> 
        </Link>;
        let r = new RegExp( '^' + (this.props.urlMatch ? this.props.urlMatch : this.props.url ) );
        let active = false;

        if( r.exec( this.state.l ) )
            active = true;

        if( this.props.children ){

            preChild = <Link href={this.props.url} >
                <i className={this.props.fa}></i> 
                <span className="nav-label">{this.props.label}</span> 
                <span className="fa arrow"></span>
            </Link>;

            child = <ul className={'nav nav-second-level ' + (active?'':'collapse')}>{this.props.children}</ul>;
        }

        return <li className={active ? 'active' : ''} >
                {preChild}
                {child}
        </li>;
    }
}




class  DownloadNavigationMenu extends Component {

    constructor(props){
        super(props);
        this.state = { l: window.location.pathname };
    }

    componentDidMount(){
        Route.onLocationChange( () => {
            this.setState({l : window.location.pathname } );
        });
    }

    render(){
        let props=this.props;
        return Object.keys( props.item ).map( key => {  
        const isDir = key.match( /^[0-9]+$/i );
            if(isDir)
                return undefined;
            let url = props.baseUrl + `${key}`;
            let active = false;
            let location = decodeURIComponent(this.state.l)
            if( (new RegExp( '^'+url )).exec(location) )
                active = true;
 
            return <li className={active?'active':''}>
                    <Link href={url} ><i class="fa fa-folder fa-fw"></i>{ t(key) } </Link>
                    {active?<ul className="nav nav-third-level">
                        <DownloadNavigationMenu item={props.item[key]} baseUrl={props.baseUrl + key + '/'} />
                    </ul>:''}
            </li>;
        });
    }
}

class  FaqNavigationMenu extends Component {
   


    constructor(props){
        super(props);
        this.state = { l: window.location.pathname };
    }

    componentDidMount(){
        Route.onLocationChange( () => {
            this.setState({l : window.location.pathname } );
        });
    }

    render(){
        
        let props=this.props;
        if(!props.item)
            return <div></div>
        return Object.keys( props.item ).map( key => {  
            let folder=props.item[key].product;
        
            let questions= props.item[key].questions;
           
            let url = props.baseUrl + `${folder}`;
            let active = false;
            let location = decodeURIComponent(this.state.l)
            if( (new RegExp( '^'+url )).exec(location) )
                active = true;
            
            return <li className={active?'active':''}>
                    <Link href={url} ><i class="fa fa-folder fa-fw"></i>{ t(folder) } </Link>
                    {active?<ul className="nav nav-third-level">
                        <FaqNavigationMenu  baseUrl={props.baseUrl + folder + '/'} />
                    </ul>:''}
            </li>;
        });
    }
}


function Row(props){
    const s_props = {
        ...props,
        className: 'row ' + ( props.className ? props.className : '' )
    };

    return <div {...s_props}>{props.children}</div>
}

function FormInputSelectOption(props){
    return <option {...props}>{props.label}</option>;
}

class FormInputSelect extends Component {
    render(){
        const sprops = { 
            value: '',
            values: false,
            onChange: () => {},
            ...this.props 
        };

        let chl = this.props.children;

        if( sprops.values ){
            chl = Object.keys( sprops.values ).map( k => {
                const selection = k == sprops.value ? { selected:'selected'} : {};
                return <FormInputSelectOption key={k} label={sprops.values[k]} value={k} {...selection} />
            });
        }

        const disabled = sprops.disabled ? { disabled: true } : {};

        return <FormInputContainer {...sprops}>
            <select className="form-control" onChange={se=>{sprops.onChange(se.target.value)}} value={sprops.value} {...disabled}>
                    {chl}
                </select>
        </FormInputContainer>;
    }
}

class FormInputRadio extends Component {

    constructor(props){
        super(props);

        this.ref = React.createRef();
    }

    componentDidMount(){
        /*eslint-disable no-undef*/
        /*setTimeout( ()=>{

            const el = findDOMNode( this.ref.current );
            $(el).iCheck({
                checkboxClass: 'icheckbox_square-green',
                radioClass: 'iradio_square-green',
            }).on('ifChecked',()=>{ console.log( 'a' ); this.props.onChange( true ) })
            .on('ifUnchecked',()=>{ console.log( 'b' ); this.props.onChange( false ) });
        } , 50 );*/
        
        /*eslint-enable no-undef*/
    }

    componentDidUpdate( prevProps , prevState ){
        /*eslint-disable no-undef*/
        /*setTimeout( ()=>{

            const el = findDOMNode( this.ref.current );
            console.log( 'ich' + (this.props.checked == true) );
            $(el).iCheck( this.props.checked == true ? 'check' : 'uncheck' );
        } , 50 );*/
        
        /*eslint-enable no-undef*/
    }

    render(){

        const classes= this.props.checked ? "checked icheckbox_square-green": "icheckbox_square-green";

        return <div className="i-checks" {...this.props}>
            <label className=""> 
                <div className={classes} style={{position: 'relative'}}>
                    <input type="checkbox" style={{position: 'absolute', opacity: 0}} />
                </div><i></i> {this.props.label} 
            </label></div>;

        const checked = this.props.checked ? { checked:'checked' } : {};

        return <div class="i-checks" ref={this.ref}>
            <label> <input type="checkbox" 
                /> 
                <i></i> {this.props.label} </label>
        </div>
    }
}


class FormInputDate extends Component {
    constructor(props){
        super(props);

        this.inputRef = React.createRef();

        this.state = {
            value: moment(this.props.value).format('DD/MM/YYYY'),
            focus: false
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidUpdate( prevProps, prevState ){
        if( prevProps.value != this.props.value && !this.state.focus ){
            
            /* eslint-disable no-undef */
            jQuery(this.inputRef.current).datepicker('setDate', moment(this.props.value).toDate() );
            /* eslint-enable no-undef */
        }
    }

    componentDidMount(){
        /* eslint-disable no-undef */
        jQuery(this.inputRef.current).datepicker({
            format: 'dd/mm/yyyy',
            autoclose: true,
        }).on('changeDate', e => {
            this.props.onChange( moment( e.date ).format( 'YYYY-MM-DD' ) );
        }).datepicker('setDate', moment(this.props.value).toDate() );

        /* eslint-enable no-undef */
    }

    componentWillUnmount(){

    }

    onChange( se ){

        this.setState({value: se.target.value});

        let match = se.target.value.match( /^([0-9]{1,2})[\/-]([0-9]{1,2})[\/-]([0-9]{4})$/i );

        if( !match ){
            match = se.target.value.match( /^([0-9]{1,2})[\/-]([0-9]{1,2})[\/-]*$/i );
            if( match ){
                match[3] = moment().year();
            }
        }

        if( match ){

            const v = moment(this.props.value)
                .date(match[1])
                .month(match[2] -1)
                .year(match[3])
                .format( 'YYYY-MM-DD' );

            if( this.props.onChange )
                this.props.onChange( v );

        }
    }

    render(){

        const inputProps = {
            enable: true,
            onChange: () => {},
            ...this.props
        };

        let additionalProps = this.props.inputProps || {};
        if( !inputProps.enable )
            additionalProps['readOnly'] = true;

        return <FormInputContainer {...this.props} >
            <input type="text" className="form-control" 
                {...additionalProps}
                value={this.state.value}
                ref={this.inputRef} 
                onChange={this.onChange}
                onFocus={se=>{this.setState({focus: true})}}
                onBlur={se=>{this.setState({
                    value: moment(this.props.value).format('DD/MM/YYYY'),
                    focus: false})}} />
        </FormInputContainer>;
    }

    focus(){
        this.inputRef.current.focus();
    }
}

class FormInputSelect2 extends Component {
    constructor(props){
        super(props);

        this.ref = React.createRef();
    }

    componentDidUpdate(prevProps, prevState){
        /*eslint-disable no-undef*/
        const el = findDOMNode( this.ref.current );
        $(el).find('select').select2('destroy');  
        /*eslint-enable no-undef*/

        this.initSelect2();
    }

    initSelect2(){
        /*eslint-disable no-undef*/
        
        const el = findDOMNode( this.ref.current );
        console.log( el );
        $(el).find('select').select2({ width: '100%'/* , data: Object.keys(this.props.values).map( v => { return { id: v, value: this.props.values[v] }; })*/ })
            .on('select2:select' , e => {
                console.log( this.props.onChange( e.params.data.id ) );
            });
        console.log( 'Init selcet2');
        /*eslint-enable no-undef*/
    }

    componentDidMount(){
        this.initSelect2();
    }

    render(){
        return <FormInputSelect {...this.props} ref={this.ref} >
            {this.props.children}
        </FormInputSelect>;
    }
}

function FormDashline(){
    return <div className="hr-line-dashed"></div>;
}

class FormInputContainer extends Component {

    static VERTICAL = 'V';
    static HORIZONTAL = 'H';

    render(){
        const s_props = { 
            label: false, 
            className: '',
            direction: FormInputContainer.VERTICAL 
        , ...this.props };

        const label = s_props.label ? <label className={'control-label' + (s_props.direction === FormInputContainer.VERTICAL ? ' col-sm-2' : '')}>
        {s_props.label}</label> : '';

        return <div className={s_props.className + " form-group"+(this.props.error?' has-error':'')}>
            {label}
            <div className={s_props.direction === FormInputContainer.VERTICAL ? ' col-sm-10' : ''}>
                {s_props.children}
                {this.props.errorLabel&&this.props.error?<span class="help-block">{this.props.errorLabel}</span>:null}
            </div>
        </div>;
    }
}

function FormButtons(props){

    let s_props = { 
        cancelLabel: t('Cancel'),
        onCancel: () => {},

        saveLabel: t('Save'),
        onSave: se => {},

        direction: FormInputContainer.VERTICAL,

        saveEnable: typeof( props.saveEnable ) !== 'undefined' ? props.saveEnable : true
    , ...props };

    let cancel = null;
    if( s_props.cancelLabel )
        cancel = <a className="btn btn-white" href="#"
                        onClick={se => {se.preventDefault(); s_props.onCancel(se)}}>
                    {s_props.cancelLabel}</a>;
                    
    const saveEnable = s_props.saveEnable ? {} : { disabled: 'disabled' };

    return <div className="form-group">
        <div className={ s_props.direction === FormInputContainer.VERTICAL ? "col-sm-4 col-sm-offset-2" : ""}>

            {cancel}

            <a className="btn btn-primary" style={{marginLeft: '10px'}}
                href="#"
                onClick={s_props.onSave} {...saveEnable}>
            {s_props.saveLabel}</a>

        </div>
    </div>;
}

class FormInputText extends Component {
    constructor(props){
        super(props);

        this.inputRef = React.createRef();
    }

    render(){

        const inputProps = {
            enable: true,
            onChange: () => {},
            ...this.props
        };

        let additionalProps = this.props.inputProps || {};
        if( !inputProps.enable )
            additionalProps['readOnly'] = true;

        if( this.props.placeholder )
            additionalProps['placeholder'] = this.props.placeholder;

        const disabled = inputProps.disabled ? { disabled: true } : {};

        return <FormInputContainer {...this.props} >
            <input type="text" className="form-control" value={this.props.value} {...additionalProps}
               {...disabled}
                onChange={se => {inputProps.onChange(se)}} ref={this.inputRef} />
        </FormInputContainer>;
    }

    focus(){
        this.inputRef.current.focus();
    }
}

class GoogleMap extends Component {

    constructor(props){
        super(props);

        if( !FormInputMap.mapCounter )
            FormInputMap.mapCounter = 0;

        FormInputMap.mapCounter++;

        this.state = {
            key: `_map_c_${FormInputMap.mapCounter}`,
            lat: props.lat,
            lng: props.lng,
            googleApiReady: this.googleExist(),
            mapInitalized: false,
        };

    }

    googleExist(){
        return  typeof( google ) !== 'undefined';
    }

    checkGoogleApi(){
        console.log( 'Checking google' );
        this.setState({ googleApiReady: this.googleExist() })
    }

    componentDidMount(){

        if( !this.googleExist() ){

            console.log( `Loading Google Map Scripts` );
            var script = document.createElement("script");
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB_W9CC2FHzVHpAChOj2OvEGafOa28tmdA`;
            document.head.appendChild(script);

            this.googleLoadingTimer = setInterval( () => { 
                this.checkGoogleApi(); 
                if( this.googleExist() )
                    clearInterval( this.googleLoadingTimer );
            } , 100 );
        }

    }

    render(){

        if( this.state.googleApiReady && !this.state.mapInitalized ){
            /*eslint-disable no-undef*/
            const element = document.getElementById( this.state.key );
            if( element ){
                this.map = new google.maps.Map( element , {
                    center: {lat: parseFloat(this.props.lat), lng: parseFloat(this.props.lng)},
                    zoom: parseFloat(this.props.zoom)
                });
            } else 
                return null;
            
            console.log( `Initializing map ${this.state.key}`)
            /*eslint-enalbe no-undef*/

            this.setState({mapInitalized: true});

            if( this.props.onReady && this.map )
                this.props.onReady( this.map );
        }

        return <div id={this.state.key} {...this.props}></div>;
    }
}

class FormInputMap extends Component {

    constructor(props){
        super(props);

        this.onMapReady = this.onMapReady.bind(this);

        this.state = {
            lat: typeof( props.lat ) === 'number' ? props.lat : parseFloat( props.lat ),
            lng: typeof( props.lng ) === 'number' ? props.lng : parseFloat( props.lng ),
        };

        this.mapDragging = false;
    }

    onMapReady( map ){
        this.map = map;
        this.forceUpdate();

        this.map.addListener( 'dragstart', () => {
            this.mapDragging = true;
        });
        this.map.addListener( 'dragend', () => {
            this.mapDragging = false;
        });

        this.map.addListener( 'center_changed', () => {
            const center = this.map.getCenter();
            
            if( this.props.onChange && isNumber( center.lat() ) )
                this.props.onChange( center.lat() , center.lng() );
        });
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            lat: typeof( nextProps.lat ) === 'number' ? nextProps.lat : parseFloat( nextProps.lat ),
            lng: typeof( nextProps.lng ) === 'number' ? nextProps.lng : parseFloat( nextProps.lng ),
        });
    }

    render(){

        if( !this.marker && this.map ){
            this.marker = new google.maps.Marker({position: {
                lat: this.state.lat,
                lng: this.state.lng 
            } , map: this.map});
        } else {
            if( this.marker ){
                const loc = new google.maps.LatLng( this.state.lat, this.state.lng );

                this.marker.setPosition( loc );

                if( !this.mapDragging )
                    this.map.panTo( loc );
            }
            
        }

        return <FormInputContainer label={this.props.label} direction={this.props.direction}>

            <div className="row" style={{marginBottom: '10px'}}>

                <div className="col-sm-6">
                    <label>{t('Latitude')}</label>
                </div>
                <div className="col-sm-6">
                    <label>{t('Longitude')}</label>
                </div>

                <div className="col-sm-6">
                    <input className="form-control" value={this.state.lat} 
                        onChange={se => { this.props.onChange( se.target.value , this.state.lng ) }} />
                </div>
                <div className="col-sm-6">
                    <input className="form-control" value={this.state.lng}
                        onChange={se => { this.props.onChange( this.state.lat , se.target.value ) }} />
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <GoogleMap lat={this.props.lat} lng={this.props.lng} zoom="18" 
                        onReady={this.onMapReady} style={{height: '400px'}} />
                </div>
            </div>
            
        </FormInputContainer>
    }

}

class SweetAlert extends Component {
    constructor(props){
        super(props);

        this.state = pweb().store.getState().sweetAlert;

        pwebs().subscribe( () => { this.setState( pweb().store.getState().sweetAlert )} );
    }

    static close(){
        pwebs().dispatch( pweb().closeAlert() );
    }

    static show( title , message , buttons ){
        SweetAlert.buttons = buttons;
        pwebs().dispatch( pweb().showAlert( title , message , buttons ) );
    }

    render(){
        
        if( !this.state.visible )
            return null;

        return <div className="SweetAlert" >
            <div className="SweetAlertBg" ></div>

            <div className="SweetAlertBox" onClick={se=>{se.preventDefault()}} style={this.props.boxStyle}>
                <h3>{this.state.title}</h3>
                <p>{this.state.content}</p>

                <div className="SweetAlertButtons">{this.state.buttons.map( button => {
                    return <button className="btn btn-default" {...button}>{button.caption}</button>
                })}</div>
            </div>

        </div>;
    }
}

class FormInputPassword extends Component {
    render(){
        return <FormInputContainer {...this.props} >
            <input type="password" className="form-control" value={this.props.value}
                onChange={se => {if(this.props.onChange) this.props.onChange(se)}} />
        </FormInputContainer>;
    }
}

class BigBannerStats extends Component {
    render(){
        return <div class="widget style1 navy-bg">
            <div class="row">
                <div class="col-xs-4">
                    <i class="fa fa-truck fa-3x"></i>
                </div>
                <div class="col-xs-8 text-right">
                    <span> {this.props.label} {moment().year()-1} / {moment().year()}</span>
                    {this.props.value && ( 
                        <h3 class="font-bold">{this.props.value}</h3>
                    )} {!this.props.value && ( 
                        <h3 class="font-bold"><i className="fa fa-spinner fa-spin"></i></h3>
                    )}                            
                </div>
            </div>
        </div>;
    }
}

class PaginationButtons extends Component {
    render(){

        const props = {
            onPageClicked: () => {},
            range: 3,
            max: 1,
            currentPage: 1,
            ...this.props
        };

        let pages_numbers = [];

        for(let p = props.currentPage - props.range;p < props.currentPage+props.range;p++){
            if( p < 0 || p > props.max-1 )
                continue;
            pages_numbers.push(p);
        }

        const pages = pages_numbers.map( page_number => {
            return <li key={page_number} className={'paginate_button'+(page_number===props.currentPage?' active':'')}>
                <a href="#" onClick={se=>{se.preventDefault();props.onPageClicked(page_number)}} >
                    {page_number+1}</a>
            </li>;
        });

        return <div className="paging_simple_numbers">
            <ul className="pagination">

                { props.currentPage > 1 ? (
                    <li className="paginate_button previous">
                        <a href="#" onClick={se=>{se.preventDefault();props.onPageClicked(props.currentPage-1)}} >
                            {t('Previous')}</a>
                    </li>
                ) : undefined }

                {pages}

                { props.currentPage < props.max-1 ? (
                    <li className="paginate_button next">
                        <a href="#" onClick={se=>{se.preventDefault();props.onPageClicked(props.currentPage+1)}} >
                            {t('Next')}</a>
                    </li>
                ) : undefined }
            </ul>
        </div>;
    }
}

class ScrollBasedList extends Component {
    constructor(props){
        super(props);

        this.state = {
            visibleItems: [ this.props.requestNextItemIdentifier() ],
            itemsLoading: 1,
            stopRequired: false,
        };

        this.placeholderRef = React.createRef();

        this.checkNextItems = this.checkNextItems.bind(this);
    }

    componentWillReceiveProps(){
        this.setState({ 
            visibleItems: [ this.props.requestNextItemIdentifier() ],
            itemsLoading: 1 });
    }

    componentDidMount(){
        this.timerHandle = setInterval( this.checkNextItems , 250 );
    }

    componentWillUnmount(){
        clearInterval( this.timerHandle );
    }

    checkNextItems(){

        if( !this.placeholderRef ) return;
        if( !this.placeholderRef.current ) return;

        const rect = this.placeholderRef.current.getBoundingClientRect();

        const wHeight = window.innerHeight * 1.5;
        
        if( rect.top < wHeight && this.state.itemsLoading < 2 && !this.state.stopRequired ){

            const new_item = this.props.requestNextItemIdentifier( this.state.visibleItems[
                this.state.visibleItems.length-1
            ] );

            this.setState({
                visibleItems: this.state.visibleItems.concat( [ new_item ] ),
                itemsLoading: this.state.itemsLoading+1
            });
        }
        
    }

    render(){
        return <div>
            {this.state.visibleItems.map( item => {
                return this.props.requestItem( item , stopRequired => { 
                    this.setState({
                        itemsLoading: this.state.itemsLoading-1,
                        stopRequired: stopRequired,
                    });
                } );
            })}

            <div ref={this.placeholderRef} />

        </div>;
    }
}

export { pweb , pwebs, Loader , PrimedLogo, FormInputPassword, FormInputMap,
    GoogleMap, FormInputText, FormButtons, FormInputContainer , FormDashline, FormInputSelect,
    FormInputSelectOption, ContainerHeader, RouteRedirect, Link, Route, BoxContainer,
    Utils, PaginationButtons, FormInputImage, SweetAlert, Row, BigBannerStats, FormInputSelect2,
    NavigationMenuItem , NavigationMenuSubItem , DownloadNavigationMenu, ScrollBasedList,
    FormInputRadio, FormInputDate,FaqNavigationMenu };
