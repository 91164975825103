import React, {Component, useEffect, useState} from 'react';
import ChartistGraph from 'react-chartist';

import accounting from 'accounting';

import ClientJs from 'clientjs';

import moment from 'moment';
import './PrivateArea.css';
import './template/style.css';
import t from './i18n';
import {
    pweb, pwebs, ContainerHeader, Link, Route, BoxContainer, SweetAlert,
    Loader, Row, Utils, BigBannerStats, NavigationMenuItem,
    NavigationMenuSubItem, DownloadNavigationMenu,FaqNavigationMenu, ScrollBasedList, FormInputImage
} from './Common.js';
import { Downloads } from './Downloads';

import { OrderTracking, OrdersSearch, OrderView, OrdersListByMonth, 
    OrdersTable, OrdersList, OrdersUtil } from './Order.js'

import { Login , ShippingAddresses , UserEdit , UserEditContact ,
    GeneratePasswordFlow } from './User.js';

import { AdminRoutes, AdminNavigationMenu } from './Admin.js';

import { DocumentsRoutes } from './Documents.js'

import { OrderCreationRoutes } from './OrderCreation.js';

import ReactGA from 'react-ga';
import {PriWebApp} from "./Globals";
    

moment.defineLocale('it', {
    months : 'Gennaio_Febbraio_Marzo_Aprile_Maggio_Giugno_Luglio_Agosto_Settembre_Ottobre_Novembre_Dicembre'.split('_'),
    monthsShort : 'Gen_Feb_Mar_Apr_Mag_Giu_Lug_Ago_Set_Ott_Nov_Dic'.split('_'),
    weekdays : 'domenica_lunedì_martedì_mercoledì_giovedì_venerdì_sabato'.split('_'),
    weekdaysShort : 'dom_lun_mar_mer_gio_ven_sab'.split('_'),
    weekdaysMin : 'do_lu_ma_me_gi_ve_sa'.split('_'),
    longDateFormat : {
        LT : 'HH:mm',
        LTS : 'HH:mm:ss',
        L : 'DD/MM/YYYY',
        LL : 'D MMMM YYYY',
        LLL : 'D MMMM YYYY HH:mm',
        LLLL : 'dddd D MMMM YYYY HH:mm'
    },
    calendar : {
        sameDay: '[Oggi alle] LT',
        nextDay: '[Domani alle] LT',
        nextWeek: 'dddd [alle] LT',
        lastDay: '[Ieri alle] LT',
        lastWeek: function () {
            switch (this.day()) {
                case 0:
                    return '[la scorsa] dddd [alle] LT';
                default:
                    return '[lo scorso] dddd [alle] LT';
            }
        },
        sameElse: 'L'
    },
    relativeTime : {
        future : function (s) {
            return ((/^[0-9].+$/).test(s) ? 'tra' : 'in') + ' ' + s;
        },
        past : '%s fa',
        s : 'alcuni secondi',
        ss : '%d secondi',
        m : 'un minuto',
        mm : '%d minuti',
        h : 'un\'ora',
        hh : '%d ore',
        d : 'un giorno',
        dd : '%d giorni',
        M : 'un mese',
        MM : '%d mesi',
        y : 'un anno',
        yy : '%d anni'
    },
    dayOfMonthOrdinalParse : /\d{1,2}º/,
    ordinal: '%dº',
    week : {
        dow : 1, // Monday is the first day of the week.
        doy : 4  // The week that contains Jan 4th is the first week of the year.
    }
}); 

class BannerPopup extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            ready: false
        };

        this.onClose = this.onClose.bind(this);
        this.onImgLoad = this.onImgLoad.bind(this);

    }

    componentDidMount(){
        /*pweb().fetch( 'admin' , 'banners' , 'active' , 'modal' , msg => {

            if( !msg.data )
                return;
                
            let popups = msg.data.filter( popup => {
                return !localStorage.getItem( `popup_banner_${popup.id}` );
            } );

            if( !popups.length )
                return;

            this.setState({
                ...popups[0],
                ready: true
            });

            localStorage.setItem( `popup_banner_${popups[0].id}` , 1 );
           
        });*/
    }

    onImgLoad( se ){
        this.setState({
            imgLoaded: true
        });
    }

    onClose( se ){
        this.setState({ready: false});
    }

    render(){

        if( !this.state.ready )
            return null;


        return <div onClick={this.onClose} className="BannerModal">
            <div onClick={se=>{se.preventDefault(); se.stopPropagation()}}>
                <div>
                    <img className="img-responsive" onLoad={this.onImgLoad} src={this.state.des_image_path} />
                    {!this.state.imgLoaded && <Loader />}
                </div>
                <div className="text-center" style={{marginTop: '20px'}}>
                    <button onClick={this.onClose} 
                        className="btn btn-primary">CHIUDI</button>
                </div>
            </div>
        </div>;
    }
}

function Footer( props ){
    return <div className="footer">
        <div className="pull-right">
            Tutti i prezzi indicati sono da intendersi <strong>IVA INCLUSA</strong>
        </div>
        <div>
            <strong>Copyright</strong> Primed s.r.l. &copy; 2017-{moment().format('YYYY')}
        </div>
    </div>;
}

class BigBannerDdt extends Component {
    constructor(props){
        super(props);
        this.state = { ddt: false };
    }

    componentDidMount(){
        pweb().fetch( 'stats' , 'ddt' , msg => {
            this.setState({ ddt: msg.data });
        });
    }

    render(){
        return <Link href="/documents/active" ><BigBannerStats label="DDT da fatturare" value={this.state.ddt !== false ?
            accounting.formatMoney( this.state.ddt , '€ ' , 2 , '.' , ',' ) : false } /></Link>;
    }
}

class BigBannerOrders extends Component {
    constructor(props){
        super(props);
        this.state = { activeOrders: false };
    }

    componentDidMount(){
        pweb().fetch( 'stats' , 'activeOrders' , msg => {
            this.setState({ activeOrders: msg.data });
        });
    }

    render(){
        return <Link href="/order/active"><BigBannerStats label="Ordini in corso" value={this.state.activeOrders !== false ?
            accounting.formatMoney( this.state.activeOrders , '€ ' , 2 , '.' , ',' ) : false } />
        </Link>
    }
}

class LoaderScreen extends Component {

    componentDidMount(){
        document.body.className = "gray-bg";
    }

    componentWillUnmount(){
        document.body.className = "";
    }

    render(){
        return <div className="middle-box text-center LoaderScreen animated fadeInDown">
            <i className="fa fa-spinner fa-spin" ></i>
            <p>{t('Loading...')}</p>
        </div>;
    }
}

function ColorBox(props){
    return <span style={{
        backgroundColor: props.color,
        width: '15px',
        height: '15px',
        display: 'inline-block',
        margin: '0 15px',
        border: '1px solid black'
    }}></span>
}

class Statistics extends Component {
    constructor(props){
        super(props);

        this.state = {
            invoicesComparasion: false,
            orderQuotationComparision: false,
            activeOrders: false,
            ddt: false,
            year: moment().month() != 0 ? moment().year() : moment().year() - 1,
        };
    }

    reloadData(){

        pwebs().dispatch( pweb().setHeaderBreadcrumbs([
            {label: 'Statiche' , url:'#'},
            {label: `Anno ${this.state.year} / ${this.state.year-1}` , url:'#'},
        ]));

        this.setState ( {
            invoicesComparasion: false,
            orderQuotationComparision: false,
            orderedSamples: false,
            orderedArticles: false,
        } );

        pweb().fetch( 'stats' , 'invoicesComparasion' , this.state.year , msg => {
            if( !msg.data.labels ) return;

            msg.data.labels = msg.data.labels.map( label => { return moment( label, 'M' ).format('MMMM') });

            let totals = [ 0, 0, 0 ];

            msg.data.labels.map( (label, labelIndex) => {

                let percent = ( msg.data.series[1][labelIndex] / 
                    msg.data.series[0][labelIndex] ) - 1 ;

                percent = isNaN( percent ) || msg.data.series[1][labelIndex] == 0 ? 0 : percent * 100;

                totals[0] += msg.data.series[0][labelIndex] ? msg.data.series[0][labelIndex] : 0;
                totals[1] += msg.data.series[1][labelIndex] ? msg.data.series[1][labelIndex] : 0;
                totals[2] += percent;
            });

            totals[2] = Math.floor(((totals[1] / totals[0]) - 1 ) * 100);
            totals[2] = isNaN( totals[2] ) ? 0 : totals[2];

            this.setState({ invoicesComparasion: msg.data, invoicesComparasionTotals: totals });
        } );        

        pweb().fetch( 'stats' , 'orderQuotationComparision' , this.state.year , msg => {
            if( !msg.data.labels ) return;

            let totals = [ 0, 0 ,0 ,0, 0 ];

            msg.data.labels.map( (label, labelIndex) => {

                let percent = ( msg.data.series2[1][labelIndex] / 
                    msg.data.series2[0][labelIndex] ) - 1 ;

                percent = isNaN( percent ) || msg.data.series2[1][labelIndex] == 0 ? 0 : percent * 100;

                totals[0] += msg.data.series[0][labelIndex] ? msg.data.series[0][labelIndex] : 0;
                totals[1] += msg.data.series[1][labelIndex] ? msg.data.series[1][labelIndex] : 0;
                totals[2] += msg.data.series2[0][labelIndex] ? msg.data.series2[0][labelIndex] : 0;
                totals[3] += msg.data.series2[1][labelIndex] ? msg.data.series2[1][labelIndex] : 0;
                totals[4] += percent;
            });

            totals[4] = Math.floor(((totals[3] / totals[2]) - 1 ) * 100);
            totals[4] = isNaN( totals[4] ) ? 0 : totals[4];

            this.setState({ orderQuotationComparision: msg.data, orderQuotationComparisionTotals: totals });
        } );

        pweb().fetch( 'stats' , 'orderedSamples' , this.state.year , msg => {

            if( !msg.data.labels ) return;

            let totals = [ 0, 0 ];

            msg.data.labels.map( (label, labelIndex) => {
                totals[0] += msg.data.series[0][labelIndex] ? msg.data.series[0][labelIndex] : 0;
                totals[1] += msg.data.series[1][labelIndex] ? msg.data.series[1][labelIndex] : 0;
            });

            this.setState({ orderedSamples: msg.data, orderedSamplesTotals: totals });
        } );

        pweb().fetch( 'stats' , 'orderedArticles' , this.state.year , msg => {

            if( !msg.data.labels ) return;

            let totals = [ 0, 0 ];

            msg.data.labels.map( (label, labelIndex) => {
                totals[0] += msg.data.series[0][labelIndex] ? msg.data.series[0][labelIndex] : 0;
            });

            this.setState({ orderedArticles: msg.data, orderedArticlesTotals: totals });
        } );

    }

    componentDidUpdate( prevProps , prevState ){

        if( this.state.year !== prevState.year ){
            this.reloadData();
        }
    }

    componentDidMount(){
        this.reloadData();
    }

    render(){
       
        var options = {
            seriesBarDistance: 25,
            reverseData: true,
            horizontalBars: false,
            axisY: {
                offset: 70
            },
        };

        let invoicesComparasionInverse = false;

        if( this.state.invoicesComparasion )
            invoicesComparasionInverse = {
                labels: this.state.invoicesComparasion.labels.slice().reverse(),
                series: [ this.state.invoicesComparasion.series[1].slice().reverse() , this.state.invoicesComparasion.series[0].slice().reverse() ]
            };

        var type = 'Bar';

        let years = [];
        for( let y = moment().year(); y >= 2014; y-- )
            years.push( y );

        return <div className="row" id="Stats">

            <div className="col-lg-4 col-sm-12">
                <div class="widget style1 navy-bg">
                    <div class="row">
                        <div class="col-xs-4">
                            <i class="fa fa-calendar-alt fa-3x"></i>
                        </div>
                        <div class="col-xs-8 text-right">
                            <span> Anno visualizzato </span>
                            <select className="form-control" style={{color: 'black', fontSize: '12px', height: '22px', padding: '1px'}} 
                                onChange={se=>{this.setState({year: se.target.value})}}>{years.map( y => {
                                return <option value={y} {...this.state.year==y?{selected:true}:{}}>{y-1} / {y}</option>;
                            })}</select>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-sm-6">
                <BigBannerOrders />
            </div>

            <div className="col-lg-4 col-sm-6">
                <BigBannerDdt />
            </div>

            <div className="col-lg-6">
                <BoxContainer title={'Comparazione fatturato'} >
                    { this.state.invoicesComparasion ? ( 
                    <Row>
                        <div className="col-lg-12">
                                <table class="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{moment(this.state.invoicesComparasion.untilDate).year()-1}</th>
                                        <th>{moment(this.state.invoicesComparasion.untilDate).year()}</th>
                                        <th>&Delta;</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.invoicesComparasion.labels.map( (label,labelIndex) => {
                                            let percent = ( this.state.invoicesComparasion.series[1][labelIndex] / 
                                                this.state.invoicesComparasion.series[0][labelIndex] ) - 1 ;

                                            percent = !isFinite( percent ) ? 0 : percent * 100;

                                            return <tr>
                                                <td>{label}</td>
                                                <td>{ accounting.formatMoney( this.state.invoicesComparasion.series[0][labelIndex] , '€ ' , 2 , '.' , ',' )}</td>
                                                <td>{ accounting.formatMoney( this.state.invoicesComparasion.series[1][labelIndex] , '€ ' , 2 , '.' , ',' )}</td>
                                                <td className={percent > 0 ? 'text-success' : 'text-navy' }> <i class="fa fa-level-up"></i> {percent.toFixed(0)}% </td>
                                            </tr>
                                        })}
                                    
                                        <tr>
                                            <td>{t('Totali')}</td>
                                            <td>{ accounting.formatMoney( this.state.invoicesComparasionTotals[0] , '€ ' , 2 , '.' , ',' ) }</td>
                                            <td>{ accounting.formatMoney( this.state.invoicesComparasionTotals[1] , '€ ' , 2 , '.' , ',' ) }</td>
                                            <td className={this.state.invoicesComparasionTotals[2] > 0 ? 'text-success' : 'text-navy' }> 
                                                <i class="fa fa-level-up"></i> {this.state.invoicesComparasionTotals[2].toFixed(0)}% </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                    </Row>
                    ) : <Loader /> }
                </BoxContainer>
            </div>

            <div className="col-lg-6">  
            
            
            <BoxContainer title={'Comparazione ordini/preventivi al ' + (this.state.orderQuotationComparision ? 
                moment( this.state.orderQuotationComparision.untilDate ).format( 'DD/MM/YYYY' ) : '-') } >

                    {this.state.orderQuotationComparision ? 
                        <Row>
                            { /* <div className="col-lg-6">
                                <Row>
                                    <div className="text-center">
                                        <ColorBox color="red" /> {moment().year()}
                                        <ColorBox color="grey" /> {moment().year()-1}
                                    </div>
                                </Row>
                                <ChartistGraph style={{height: '500px'}} data={this.state.orderQuotationComparision} options={{...options,horizontalBars: true}} type={type} />
                            </div> */ }
                            <div className="col-lg-12">
                                <table class="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{moment(this.state.orderQuotationComparision.untilDate).year()-1}</th>
                                        <th>{moment(this.state.orderQuotationComparision.untilDate).year()}</th>
                                        <th>{moment(this.state.orderQuotationComparision.untilDate).year()-1}</th>
                                        <th>{moment(this.state.orderQuotationComparision.untilDate).year()}</th>
                                        <th>&Delta;</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.orderQuotationComparision.labels.map( (label,labelIndex) => {
                                            let percent = ( this.state.orderQuotationComparision.series2[1][labelIndex] / 
                                                this.state.orderQuotationComparision.series2[0][labelIndex] ) - 1 ;

                                            percent = !isFinite( percent ) ? 0 : percent * 100;

                                            return <tr>
                                                <td>{label}</td>
                                                <td>{ this.state.orderQuotationComparision.series[0][labelIndex]}</td>
                                                <td>{ this.state.orderQuotationComparision.series[1][labelIndex]}</td>
                                                <td>{ accounting.formatMoney( this.state.orderQuotationComparision.series2[0][labelIndex] , '€ ' , 2 , '.' , ',' )}</td>
                                                <td>{ accounting.formatMoney( this.state.orderQuotationComparision.series2[1][labelIndex] , '€ ' , 2 , '.' , ',' )}</td>
                                                <td className={percent > 0 ? 'text-success' : 'text-navy' }> <i class="fa fa-level-up"></i> {percent.toFixed(0)}% </td>
                                            </tr>
                                        })}

                                        <tr className="totals">
                                            <td>{t('Totali')}</td>
                                            <td>{ this.state.orderQuotationComparisionTotals[0]}</td>
                                            <td>{ this.state.orderQuotationComparisionTotals[1]}</td>
                                            <td>{ accounting.formatMoney( this.state.orderQuotationComparisionTotals[2] , '€ ' , 2 , '.' , ',' )}</td>
                                            <td>{ accounting.formatMoney( this.state.orderQuotationComparisionTotals[3] , '€ ' , 2 , '.' , ',' )}</td>
                                            <td className={0 > 0 ? 'text-success' : 'text-navy' }> <i class="fa fa-level-up"></i> {this.state.orderQuotationComparisionTotals[4]}% </td>
                                        </tr>
                                    
                                    </tbody>
                                </table>
                            </div>
                        </Row> 
                    : <Loader /> }
                
                </BoxContainer> 
            
            </div>

            <div className="col-lg-12">
                <BoxContainer title={'Comparazione fatturato'} >
                    {this.state.invoicesComparasion ? 
                        <Row>
                            <div className="col-lg-12">
                                <Row>
                                    <div className="text-center">
                                        <ColorBox color="grey" /> {moment(this.state.invoicesComparasion.untilDate).year()-1}
                                        <ColorBox color="red" /> {moment(this.state.invoicesComparasion.untilDate).year()}
                                    </div>
                                </Row>
                                <ChartistGraph style={{height: '500px'}} data={invoicesComparasionInverse} options={options} type={type} />
                            </div>
                            
                        </Row> 
                    : <Loader /> }
                
                </BoxContainer> 
            </div>

            
            <div className="col-lg-6">
            
            <BoxContainer title={'Campioni consegnati al ' + (this.state.orderedSamples ? 
                moment( this.state.orderedSamples.untilDate ).format( 'DD/MM/YYYY' ) : '-') } >

                    {this.state.orderedSamples ? 
                        <Row>
                            <div className="col-lg-12">
                                <table class="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>Articolo</th>
                                        <th>{moment(this.state.orderedSamples.untilDate).year()-1}</th>
                                        <th>{moment(this.state.orderedSamples.untilDate).year()}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.orderedSamples.labels.map( (label,labelIndex) => {
                                            return <tr>
                                                <td>{label}</td>
                                                <td>{ this.state.orderedSamples.series[0][labelIndex]}</td>
                                                <td>{ this.state.orderedSamples.series[1][labelIndex]}</td>
                                            </tr>
                                        })}

                                        <tr>
                                            <td>{t('Totali')}</td>
                                            <td>{ this.state.orderedSamplesTotals[0]}</td>
                                            <td>{ this.state.orderedSamplesTotals[1]}</td>
                                        </tr>
                                    
                                    </tbody>
                                </table>
                            </div>
                        </Row> 
                    : <Loader /> }
                
                </BoxContainer>
            
            </div>

            <div className="col-lg-6">
              
                <BoxContainer title={'Articoli ordinati al ' + (this.state.orderedArticles ? 
                    moment( this.state.orderedArticles.untilDate ).format( 'DD/MM/YYYY' ) : '-') } >

                    {this.state.orderedArticles ? 
                        <Row>
                            <div className="col-lg-12">
                                <table class="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>Articolo</th>
                                        <th>{moment(this.state.orderedArticles.untilDate).year()}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.orderedArticles.labels.map( (label,labelIndex) => {
                                            return <tr>
                                                <td>{label}</td>
                                                <td>{ this.state.orderedArticles.series[0][labelIndex]}</td>
                                            </tr>
                                        })}

                                        <tr>
                                            <td>{t('Totali')}</td>
                                            <td>{ this.state.orderedArticlesTotals[0]}</td>
                                        </tr>
                                    
                                    </tbody>
                                </table>
                            </div>
                        </Row> 
                    : <Loader /> }
                
                </BoxContainer> 
            </div>

        </div>;
    }
}

function faqArray(){
    return [
        {
            product: "COMMERCIALE",
            questions: [
       
            ]
          },
          {
            product: "SWITCH3",
            questions: [
              {
                question: "Molla scarica",
                description: "In questo questions mostriamo in che modo caricare una molla per la zanzariera SWITCH3.",
                link: "cYOn6ielAy4"
              },
              {
                question: "Chiusura parte inferiore",
                description: "In questo questions mostriamo in che modo risolvere il problema della mancata chiusura della zanzariera nella parte inferiore.",
                link: "R3rLkP81o8g"
              },
              {
                question: "Chiusura parte superiore",
                description: "In questo questions mostriamo in che modo risolvere il problema della mancata chiusura della zanzariera nella parte superiore.",
                link: "Y5W5lz4m2NY"
              }
            ]
          },
          {
            product: "DIVA",
            questions: [
              {
                question: "Catenaria fuori sede",
                description: "In questo questions mostriamo in che modo risolvere il problema della fuoriuscita dal binario inferiore della catenaria.",
                link: "_RiXJr4QVMI"
              },
              {
                question: "Filo troppo corto",
                description: "In questo questions mostriamo in che modo risolvere il problema del filo troppo corto che impedisce installazione terminale a muro",
                link: "hAzGfFIyQjo"
              },
              {
                question: "Filo troppo lungo",
                description: "In questo questions mostriamo in che modo risolvere il problema del filo troppo lungo che penzola dal binario superiore e non permette il buon funzionamento della zanzariera.",
                link: "ngCzINZXAns"
              },
              {
                question: "Fuoriuscita rete catenaria",
                description: "In questo questions mostriamo in che modo risolvere il problema della fuoriuscita della rete dal binario inferiore che non permette il buon funzionamento della zanzariera.",
                link: "-7u_zb4UWWo"
              }
            ]
          },
    ]
  }
  
class NavigationMenu extends Component {
    constructor(props){
        super(props);

        this.state = {
            ...pweb().store.getState()
        };

        pwebs().subscribe( () => { this.setState( pweb().store.getState() )} );
    }

    render(){

        let admin_section = undefined;

        if( this.state.userData && this.state.userData.is_admin ){
            admin_section = <AdminNavigationMenu />;
        }

        return <nav className="navbar-default navbar-static-side" >
            <div className="sidebar-collapse">
                <ul className="nav metismenu" id="side-menu">
                    <li className="nav-header" style={{padding: '15px',backgroundColor: '#f3f3f4', height: '80px'}}>
                        <div className="dropdown profile-element">

                            <img src={"/logo_colors.png"}
                                 className={"img-responsive"} style={{marginBottom:'15px', maxWidth: '190px'}} />

                        </div>
                    </li>

                    <li className="nav-header">

                        <div style={{maxWidth: '190px'}}>
                            <form className="hor">
                                {this.state.userData.logo_token && <FormInputImage src={`${window.location.protocol}//${window.location.hostname}/api1/user/logo/${this.state.userData.logo_token}`} />}
                                {!this.state.userData.logo_token && <FormInputImage />}
                            </form>
                        </div>

                        <div className="dropdown profile-element">

                            <a data-toggle="dropdown" className="dropdown-toggle">
                                <span className="clear"> <span className="block m-t-xs"> <strong className="font-bold">
                                    {this.state.userData.cda_ana}
                                </strong>
                                </span> <span className="text-muted text-xs block">
                                    {this.state.userData.des_email}
                                </span> </span>
                            </a>
                        </div>
                        <div className="logo-element">
                            PW
                        </div>
                    </li>

                    <NavigationMenuItem fa="fa fa-th-large" label={t('Dashboard')} url="/dashboard">
                    </NavigationMenuItem>

                    { this.state.userData.quotation_enable && <NavigationMenuItem fa="fa fa-plus fa-fw" label={t('Nuovo preventivo')} url="/orderc/new">    
                    </NavigationMenuItem> }

                    { this.state.userData.quotation_enable && <NavigationMenuItem fa="fa fa-plus fa-fw" label={t('Nuovo ordine')} url="/orderc/new">    
                    </NavigationMenuItem> }

                    {/*<NavigationMenuItem fa="fa fa-user fa-fw" label={t('Profile')} url="/user" >
                        <NavigationMenuSubItem label={t('General profile')} url="/user/edit" />
                        <NavigationMenuSubItem label={t('Indirizzi spedizione')} url="/user/shipping_address" />
                        {/*<NavigationMenuSubItem label={t('Retailers profiles')} url="/user/retailers" />
                    </NavigationMenuItem>*/}

                    <NavigationMenuItem fa="fa fa-truck fa-fw" label={t('Orders')} url="/order/list" urlMatch="/order/" >
                        <NavigationMenuSubItem label={t('All orders')} url="/order/list/" />
                        <NavigationMenuSubItem label={t('New orders')} url="/order/new/" />
                        <NavigationMenuSubItem label={t('Orders in progress')} url="/order/working/" />
                        <NavigationMenuSubItem label={t('Blocked orders')} url="/order/blocked/" />
                        <NavigationMenuSubItem label={t('Quotations')} url="/order/quotations" />
                        <NavigationMenuSubItem label={t('Evaded')} url="/order/shipped/" />
                    </NavigationMenuItem>

                    <NavigationMenuItem fa="fa fa-chart-bar fa-fw" label={t('Statistics')} url="/statistics">
                    </NavigationMenuItem>

                    <NavigationMenuItem fa="fa fa-file fa-fw" label={t('Documenti fiscali')} url="/documents/report">    
                    </NavigationMenuItem>
                    <NavigationMenuItem fa="fa fa-question-circle fa-fw" label={t('Faq')} url="/faq" >
                            <FaqNavigationMenu item={faqArray()} baseUrl="/faq/" />
                    </NavigationMenuItem>
                    {this.state.downloads && (
                        <NavigationMenuItem fa="fa fa-download fa-fw" label={t('Download')} url="/downloads" >
                            <DownloadNavigationMenu item={this.state.downloads} baseUrl="/downloads/" />
                        </NavigationMenuItem>
                    )}

                    {admin_section}
                </ul>

            </div>
        </nav>;
    }
}



function DashboardTableActive(props){
    return <BoxContainer title={t('Active orders')}
        tools={ pwebs().getState().userData.quotation_enable && <Link href="/orderc/new" className="btn btn-primary btn-xs"><i className="fa fa-plus"></i> Nuovo ordine</Link>} >

        <OrdersList states={['LAV','INS','PRO']} cache={false}
            columns={[OrdersTable.columnOrderNumber, OrdersTable.columnReferer,
                OrdersTable.columnCreationDate, 
                OrdersTable.columnShipDate, OrdersTable.columnBadge]}
            fetchTotals={false}
            order="dat_doc desc , dat_cons_rich ASC" blocked={false} />
    </BoxContainer>;
}

class DashboardTableQuotations extends Component {
    constructor(props){
        super(props);

        this.state = { showButton: false };
    }

    render(){
        return <BoxContainer title={t('Quotations')} 
            tools={ pwebs().getState().userData.quotation_enable && <Link href="/orderc/new" className="btn btn-primary btn-xs"><i className="fa fa-plus"></i> Nuovo preventivo</Link>} >

            <OrdersList quotations={true} columns={[OrdersTable.columnOrderNumber, OrdersTable.columnReferer,
                OrdersTable.columnCreationDate, 
                OrdersTable.columnShipDate, OrdersTable.columnBadge]}
                startDate={moment().startOf('month').subtract(1,'month').format('DD/MM/YYYY')}
                onOrdersLoaded={orders => this.setState({ showButton: orders.length > 0})}
                cache={false}
                fetchTotals={false}
                states={{nin:['ANN']}}
            />

            {this.state.showButton && (
                <div className="text-right">
                    <Link href="/order/quotations/" className="btn btn-large btn-default">
                        {t('See complete list')} <i className="fa fa-chevron-right"></i></Link>
                </div>
            )}

        </BoxContainer>;
    }
}

class DashboardTableVoidedQuotations extends Component {
    constructor(props){
        super(props);

        this.state = { showButton: false };
    }

    render(){
        return <BoxContainer title={t('Preventivi annullati')} >

            <OrdersList quotations={true} columns={[OrdersTable.columnOrderNumber, OrdersTable.columnReferer,
                OrdersTable.columnCreationDate, 
                OrdersTable.columnShipDate, OrdersTable.columnBadge]}
                startDate={moment().startOf('month').subtract(1,'month').format('DD/MM/YYYY')}
                onOrdersLoaded={orders => this.setState({ showButton: orders.length > 0})}
                cache={true}
                fetchTotals={false}
                states={{in:['ANN']}}
            />

            { false && (
                <div className="text-right">
                    <Link href="/order/quotations/" className="btn btn-large btn-default">
                        {t('See complete list')} <i className="fa fa-chevron-right"></i></Link>
                </div>
            )}

        </BoxContainer>;
    }
}

class DashboardTableQuotationsWeb extends Component {
    constructor(props){
        super(props);

        this.state = { showButton: false };
    }

    render(){
        return <BoxContainer title={t('Preventivi web')} >
            <OrdersList 
                columns={[OrdersTable.columnOrderNumber, OrdersTable.columnReferer,
                    OrdersTable.columnCreationDate,  
                    OrdersTable.columnShipDate, OrdersTable.columnBadge, DashboardTableLast.columnTrack]}
                limit={10} cache={true}
                fetchTotals={false}
                onOrdersLoaded={orders => this.setState({ showButton: orders.length > 0 })} />
                {this.state.showButton && (
                    <div className="text-right">
                        <Link href="/order/shipped/" className="btn btn-large btn-default">
                            {t('See complete list')} <i className="fa fa-chevron-right"></i></Link>
                    </div>
                )}

        </BoxContainer>;
    }

}

class DashboardTableLast extends Component {
    constructor(props){
        super(props);

        this.state = { showButton: false };
    }

    static columnTrack(order, isLabel, onClick){
        return isLabel ? 
            <th className="hidden-md hidden-sm hidden-xs" width="5%"></th> : 
            <td className="text-left">
                {OrdersUtil.hasTracking( order ) && <Link className="btn btn-xs btn-default" target="_blank"
                    data-toggle="tooltip" data-placement="left" title="Traccia spedizione"
                    href={`/order/tracking/${OrdersUtil.formatNum(order,true)}`}>
                    <i className="fa fa-truck"></i>
                </Link>}
            </td>;
    }

    render(){
        return <BoxContainer title={t('Last orders')} >
            <OrdersList states={['EVA']}
                columns={[OrdersTable.columnOrderNumber, OrdersTable.columnReferer,
                    OrdersTable.columnCreationDate,  
                    OrdersTable.columnShipDate, OrdersTable.columnBadge, DashboardTableLast.columnTrack]}
                limit={10} cache={true}
                fetchTotals={false}
                onOrdersLoaded={orders => this.setState({ showButton: orders.length > 0 })} />
                {this.state.showButton && (
                    <div className="text-right">
                        <Link href="/order/shipped/" className="btn btn-large btn-default">
                            {t('See complete list')} <i className="fa fa-chevron-right"></i></Link>
                    </div>
                )}

        </BoxContainer>;
    }
}

class Dashboard extends Component {

    componentDidMount(){
        pwebs().dispatch( pweb().setHeaderBreadcrumbs([
            {label: 'Utente ' + pwebs().getState().userData.cda_ana , url:'/user/edit'},
            {label: 'Dashboard', url:'#'},
        ]));
    }

    

    render(){
        return <div className="row">

            {/* <div className="col-lg-4 col-sm-12">
                <div class="widget style1 navy-bg" style={{backgroundColor: '#4d4da5'}}>
                    <div class="row">
                        <div class="col-xs-4">
                            <i class="fa fa-sun fa-3x fa-spin" style={{animation: 'a 10s infinite linear'}}></i>
                        </div>
                        <div class="col-xs-8 text-right">
                            <span>Saremo chiusi per ferie </span>
                            <h3>Dal 10 al 25 Agosto 2019</h3>                     
                        </div>
                    </div>
                </div>
            </div> */ }

            { /* <div className="col-lg-4 col-sm-6">
                <BigBannerOrders />
            </div>
            
            <div className="col-lg-4 col-sm-6">
                <BigBannerDdt />
            </div> */ }

            <div className="col-md-12 DasboardButtons hidden-md hidden-lg" style={{marginBottom: '10px'}}>
                <Link href="/order/new/" className="btn btn-large btn-success">{t('New orders')}</Link>
                <Link href="/order/working/" className="btn btn-large btn-warning">{t('In progress')}</Link>
                <Link href="/order/blocked/" className="btn btn-large btn-danger">{t('Blocked')}</Link>
                <Link href="/order/quotations/" className="btn btn-large btn-default">{t('Quotations')}</Link>
                <Link href="/order/shipped/" className="btn btn-large btn-primary" style={{backgroundColor: '#51b775', borderColor: '#51b775'}}>{t('Evaded')}</Link>
            </div>

            <div className="col-md-12 col-lg-6">
                <DashboardTableActive />

                <DashboardTableLast />

                <DashboardTableVoidedQuotations />
            </div>

            <div className="col-md-12 col-lg-6">
                <BoxContainer title={t('Blocked orders')} >
                    <OrdersList blocked={true} columns={[OrdersTable.columnOrderNumber, OrdersTable.columnReferer,
                        OrdersTable.columnCreationDate, 
                        OrdersTable.columnShipDate, OrdersTable.columnBadge]}
                        fetchTotals={false} />
                </BoxContainer>

                <DashboardTableQuotations />

                <BoxContainer title={t('Voided orders')} >
                    <OrdersList states={{in:['ANN']}} quotations={-1}  columns={[OrdersTable.columnOrderNumber, OrdersTable.columnReferer,
                        OrdersTable.columnCreationDate, 
                        OrdersTable.columnShipDate, OrdersTable.columnBadge ]}
                        fetchTotals={false} />
                </BoxContainer>

            </div>

            {/*<div className="col-md-2">
                <BoxContainer title={t('Not evaded orders')} >
                    <h1 class="no-margins">123</h1>
               
                    <small>{t('Total orders waiting')}</small>
                </BoxContainer>
            </div> */ }

        </div>;
    }
}


class Faq extends Component {

    constructor(props){
        super(props);
        this.faq=faqArray();
        /*this.state = {
            ...pweb().store.getState()
        };

        pwebs().subscribe( () => { this.setState( pweb().store.getState() )} );*/
    }

    componentDidMount(){

        let loc = window.location.pathname.split('/');
       
        const labels = [{label: t('Faq'), url:'/faq'}];

        if( loc.length > 1 )
            labels.push({ label: t(`Section `) + ` ${loc[2] ? decodeURIComponent(loc[2]) : 'Faq'}"`});

        pwebs().dispatch( pweb().setHeaderBreadcrumbs( labels ) );

        Route.onLocationChange( () => {
            this.forceUpdate();
        });

    }

    getLocation(){
        return  window.location.pathname.split('/')
        .map( s => { return decodeURIComponent( s ) } )
        .filter( i => { return i.length > 0 });
    }

    tabList(){
        if( !this.faq )
        return <Loader />;
        var loc = this.getLocation();
        let faq=this.faq;
        return <div>{
                        <ul class="nav nav-tabs">{
                            Object.keys(faq).map( key => {
                                let active=loc.indexOf(faq[key].product)
                                return <li role="presentation" className={active>=0?'active':''}>
                                            <a href="#" onClick={e=>{Route.pushLocation( `/faq/${faq[key].product}` )}}> 
                                                {faq[key].product}
                                            </a>
                                        </li> 
                                       
                            })
                        }</ul>
                    }
           
                    { loc.indexOf('COMMERCIALE')>0 &&
                    <div style={{'display':'flex','flexDirection':'column'}}>
                        <img style={{'width':'800px'}} src="../doc/g1.jpg"/>
                        <img style={{'width':'800px'}} src="../doc/g2.jpg"/>
                        <img style={{'width':'800px'}} src="../doc/g3.jpg"/>
                        <img style={{'width':'800px'}} src="../doc/g4.jpg"/>
                        <img style={{'width':'800px'}} src="../doc/g5.jpg"/>
                        <img style={{'width':'800px'}} src="../doc/g6.jpg"/>
                        <img style={{'width':'800px'}} src="../doc/g7.jpg"/>       
                    </div>
                    }
                </div> 
    }

    render(){
        let faq = this.faq;
        var loc = this.getLocation();
        return (
            <div>
                {this.tabList()}
                { Object.keys(faq).map( key => {
                                let active=loc.indexOf(faq[key].product)
                                return  active>=0?<div class="list-group">
                                    {Object.keys(faq[key].questions).map(question =>{
                                        let youtubeUrl='https://www.youtube.com/embed/'+faq[key].questions[question].link;
                                        let questionActive=loc.indexOf(faq[key].questions[question].question);
                                        return <a href="#" class="list-group-item"  onClick={e=>{Route.pushLocation( `/faq/${faq[key].product}/${faq[key].questions[question].question}` )}}>
                                                    <h4 class="list-group-item-heading">{faq[key].questions[question].question}</h4>
                                                    {questionActive>=0&&<p class="list-group-item-text">{faq[key].questions[question].description}
                                                    <div style={{'margin':'40px'}}>
                                                        <iframe width="560" height="315" src={youtubeUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                    </div></p>}
                                                </a>
                                    })}
                              </div>:''
                            })}
            </div>
        );

    }
}

class Printer extends Component {

    componentDidMount(){
        document.getElementById('printer').submit();
    }

    render(){
        const locs = window.location.pathname.split( '/' );
        const name = locs[locs.length-1];

        return <div>
            <Loader />

            <form id="printer" style={{display: 'none'}} action={`${pweb().apiUrl()}/order/print/${name}`} 
                target="" method="POST">
                <input type="hidden" name="access_token" value={pwebs().getState().authorizationKey} />
            </form>

        </div>;
    }
}

class WebChat extends Component {
    constructor( props ) {
        super( props );

        this.state = {
            chatStatus: 'uninstalled', // uninstalled -> loading -> loaded -> ready
            chatAs: null,
            asFakeChat: window.location.hostname === 'localhost',
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let savedData = localStorage.getItem( PriWebApp.__save_string );
        let userData = false;
        if( savedData ){
            try {
                let data = JSON.parse( savedData );
                if( data && data.userData && data.authorizationKey )
                    userData = data.userData;
            } catch (e) {

            }
        }

        if( prevState.chatStatus == 'loading' && this.state.chatStatus == 'loaded' ){
            if( this.state.asFakeChat ){
                this.setState({
                    chatStatus: 'ready',
                    ...( userData && userData.cda_ana ? {
                        chatAs: userData.cda_ana
                    } : {
                        chatAs: 'anon'
                    })
                }) ;
            } else {

                try {

                    /* eslint-disable no-undef */
                    embeddedservice_bootstrap.settings.language = 'it'; // For example, enter                                                                                                                              'en' or 'en-US'

                    embeddedservice_bootstrap.init(
                      '00D09000002X9Lv',
                      'Primed_Commerciale_New_Chat',
                      'https://primed.my.site.com/ESWPrimedCommercialeNew1696864056032',
                      {
                          scrt2URL: 'https://primed.my.salesforce-scrt.com'
                      }
                    );

                    this.setState({
                        chatStatus: 'ready',
                    });

                    if( userData && userData.cda_ana ) {
                        window.addEventListener("onEmbeddedMessagingReady", e => {

                            const email = userData.des_email || userData.des_email1 || userData.email_arweb;

                            embeddedservice_bootstrap.prechatAPI.setVisiblePrechatFields({
                                _firstName: {value: userData.cda_ana, isEditableByEndUser: false},
                                _lastName: {value: userData.des_ragsoc, isEditableByEndUser: false},
                                _email: { value: email, isEditableByEndUser: false },
                            });



                        });
                    }

                    /* eslint-enable no-undef */


                } catch (e) {

                }
            }
        }
    }

    componentDidMount() {
        if( this.state.chatStatus === 'uninstalled' ){
            if( this.state.asFakeChat ){
                this.setState({ chatStatus: 'loading' }) ;
                setTimeout( () => {
                    this.setState({ chatStatus: 'loaded' }) ;
                } , 2000 );
            } else {

                this.setState({ chatStatus: 'loading' }) ;

                const script = document.createElement('script');
                script.src = 'https://primed.my.site.com/ESWPrimedCommercialeNew1696864056032/assets/js/bootstrap.min.js';
                script.setAttribute( 'id', 'webChatScript' );
                // Cross origin
                //script.setAttribute( "crossorigin" , 'anonymous' );

                document.body.appendChild(script);

                document.getElementById('webChatScript').addEventListener('load', () => {
                    this.setState({ chatStatus: 'loaded' }) ;
                });
            }
        }
    }

    componentWillUnmount() {
        // Salesforce Webchat has no method for unloading
        // I MUST reload the page to remove the chat
        window.location.reload();

        this.setState({ chatStatus: 'uninstalled' }) ;

        if( this.state.asFakeChat ){
        } else {
            try {
                document.getElementById('webChatScript').remove();
            } catch (e) {
                
            }
        }
    }

    render(){
        if( this.state.asFakeChat && this.state.chatStatus === 'ready' ){
            const fakeChatStyle = {
                position: 'fixed',
                bottom: 0,
                right: '50px',
                padding: '10px',
                backgroundColor: 'red',
                zIndex: 600002,
                color: 'white',
                fontSize: 18,
            };
            return <div style={fakeChatStyle}>
                FakeChat(2) as { this.state.chatAs }
            </div>;
        }

        return <React.Fragment></React.Fragment>;
    }
}

class PrivateArea extends Component {

    constructor(props){
        super(props);

        t().setLanguage('it');
        moment().locale('it');
        console.log( moment().locale() );
        
        pweb().init();

        this.state ={
            search: '',
            ...pweb().store.getState(),
        };

        pwebs().dispatch( pweb().hideLoader() );
        pwebs().subscribe( () => {
            let newState = pweb().store.getState();
            if( !newState.startupDone && this.state.startupDone  ){
                // alert( JSON.stringify( newState ) );
                return;
            }
            this.setState( newState );
        } );

        this.onSearchSubmit = this.onSearchSubmit.bind(this);
        this.onLogout = this.onLogout.bind(this);

        this.autoLoginDone = false;

        window.onerror = function (msg, url, lineNo, columnNo, error) {
            var client = new ClientJs();
            var clientData = {
                b: client.getBrowser(),
                d: client.getDevice(),
                o: client.getOS()
            };

            pweb().fetch( 'logError' , [msg,url,lineNo,columnNo,clientData] );
        };
    }

    componentDidCatch(error, errorInfo) {
        console.log([ error , errorInfo ]);
      }

    runFakeChat(){
        return window.location.hostname == 'localhost';
    }

    componentDidMount(){

        if( window.onpopstate ){
            window.onpopstate = () => {
                Route.pushLocation( window.location.pathname , false );
            }
        } else {
            window.addEventListener('popstate', () => {
                Route.pushLocation( window.location.pathname , false );
            }); 
        }
        
        ReactGA.initialize('UA-132899749-1');

        const autoLoginRe = /\/autoLogin\/([a-z0-9]+)/i.exec(  window.location.pathname );

        if( autoLoginRe && !this.autoLoginDone ){
            this.autoLoginDone = true;
            console.log( autoLoginRe[1] );

            PriWebApp.fetch( 'user' , 'login' , {email: autoLoginRe[1], password: '1'} , ( data ) => {
                if( data.status ){
                    pwebs().dispatch( pweb().login( data.data.token ) );
                    pwebs().dispatch( pweb().setUserData( data.data.user ) );
                    Route.pushLocation( '/dashboard' );
                }
            });

        }
        
        if( Route ){
            if( Route.onLocationChange ){
                Route.onLocationChange( () => {

                    ReactGA.pageview( window.location.pathname );

                    /*eslint-disable no-undef*/
                    setInterval( () => {
                        let tooltips = $('[data-toggle="tooltip"]');

                        if( window.__tooltips_length !== tooltips.length ){
                            tooltips.tooltip();
                            window.__tooltips_length = tooltips.length;

                            $('[data-toggle="tooltip"]').unbind('click.PrivateArea').bind('click.PrivateArea', ()=>{
                                setTimeout( ()=>{
                                    $('[data-toggle="tooltip"]').blur();
                                }, 600);
                                
                            });

                            console.log( 'Tooltips udpated' );
                        }
                    } , 500 );
                    /*eslint-enable no-undef*/

                    if( window.location.pathname === '/' || window.location.pathname === '' ) Route.pushLocation( '/dashboard' );
                    if( window.location.pathname === '/user' ) Route.pushLocation( '/user/edit' );
                });
            }
        }

    }

    onSearchSubmit(se){
        se.preventDefault();

        /*eslint-disable no-undef*/
        $('[data-toggle="tooltip"]').tooltip('hide');
        /*eslint-enable no-undef*/

        if( this.state.search.trim().length < 1 )
            return;
            
        this.setState({search: ''});
        Route.pushLocation( `/order/search/${encodeURIComponent(this.state.search)}` );
    }

    componentDidUpdate( prevProps , prevState ){

        /*if( this.state.userData ){
            if( this.state.userData.cda_ana ){
                ReactGA.ga().set( 'set' , 'dimension1' , this.state.userData.cda_ana );
            }
        }*/

        /*eslint-disable no-undef*/
        
        if ($(window).width() < 769) {
            $('body').addClass('body-small')
        } else {
            $('body').removeClass('body-small')
        }

        // Minimalize menu
        $('.navbar-minimalize').off('click').click(function () {
            $("body").toggleClass("mini-navbar");
            if (!$('body').hasClass('mini-navbar') || $('body').hasClass('body-small')) {
                // Hide menu in order to smoothly turn on when maximize menu
                $('#side-menu').hide();
                // For smoothly turn on menu
                setTimeout(
                    function () {
                        $('#side-menu').fadeIn(400);
                    }, 200);
            } else if ($('body').hasClass('fixed-sidebar')) {
                $('#side-menu').hide();
                setTimeout(
                    function () {
                        $('#side-menu').fadeIn(400);
                    }, 100);
            } else {
                // Remove all inline style from jquery fadeIn function to reset menu state
                $('#side-menu').removeAttr('style');
            }
        });

        /*eslint-enable no-undef*/
    }

    onLogout(){
        pwebs().dispatch( pweb().login( false ) );
    }

    render(){
 
        if( !this.state.startupDone ){
            return <LoaderScreen />;
        }

        if( window.location.pathname.match( /^\/recoverPassword[0-9]\/([a-z0-9\-]+)\/?$/i ) )
            return <GeneratePasswordFlow />;


        if( !this.state.authorizationKey ){
            return <React.Fragment>
                <WebChat />
                <Login />
            </React.Fragment>;
        }

        let content = <div> <ContainerHeader />

        { this.state.searchVisible && <div className="search-form-centered row" data-toggle="disabled-tooltip" data-placement="bottom" 
                    title="Cerca per riferimento, numero ordine oppure misure ( Ex: 1012x950 )">
            <form role="search" className="navbar-form-custom" onSubmit={this.onSearchSubmit}  >

                <div className="form-group" >

                    <i className="fa fa-search"></i>

                    <input type="text" autoComplete="off" placeholder="Cerca per riferimento, numero ordine oppure misure ( Ex: 1012x950 )"
                         className="form-control" name="top-search" id="top-search"
                        onChange={se=>this.setState({search: se.target.value})} value={this.state.search}
                         />
                </div>

                <button className="btn btn-primary">{t('Search')}</button>

            </form>
        </div> }

        { (this.state.startupDone && this.state.userData) && <WebChat
          userData={this.state.userData}
          authorizationKey={this.state.authorizationKey} /> }

        <div className="wrapper wrapper-content animated fadeInRight " >

            <Route url='/dashboard'>
                <Dashboard />
            </Route>

            <Route url="/statistics">
                <Statistics />
            </Route>

            <BannerPopup />

            {/**/}<Route url='/user/edit' >
                <UserEdit />
            </Route>

            <Route url="/user/contact">
                <UserEditContact />
            </Route>

            <Route url='/user/shipping_address' >
                <ShippingAddresses />
            </Route>{/**/}
    
            <DocumentsRoutes/>

            <Route url='/order/new' >
                <BoxContainer title={t('New orders')}>
                    <OrdersList states={['INS']} />
                </BoxContainer>
            </Route>

            <Route url='/order/active' >
                
                <BoxContainer title={t('Active orders')}>
                    <OrdersList states={['LAV','INS','PRO']}
                    columns={[OrdersTable.columnOrderNumber, OrdersTable.columnReferer,
                        OrdersTable.columnCreationDate, 
                        OrdersTable.columnShipDate, OrdersTable.columnBadge]}
                    order="dat_cons_rich ASC" blocked={true} />
                </BoxContainer>

            </Route>

            <Route url='/order/working' >
                <BoxContainer title={t('In progress')}>
                    <OrdersList states={['LAV','PRO']} />
                </BoxContainer>
            </Route>

            <Route url='/order/blocked' >
                <BoxContainer title={t('Blocked')}>
                    <OrdersList blocked={true} />
                </BoxContainer>
            </Route>

            <Route url='/order/quotations' >
                <OrdersListByMonth title={"Preventivi"} quotations={true} />
            </Route>

            <Route url='/order/shipped' >
                <OrdersListByMonth states={['EVA']} />
            </Route>

            <Route url='/order/search' >
                <OrdersSearch />
            </Route>

            <Route url='/order/list' >
                <OrdersListByMonth states={{nin:['TRA']}} />
            </Route>

            <Route url='/order/view' >
                <OrderView />
            </Route>

            <Route url='/order/tracking' >
                <OrderTracking />
            </Route>

            <Route url='^/download'>
                <Downloads />
            </Route>
            
            <Route url='^/faq'>
                <Faq />
            </Route>
            
            <Route url='/print/'>
                <Printer />
            </Route>

            <AdminRoutes />

            <OrderCreationRoutes />

        </div> </div>;

        const navContent =         <nav className="navbar navbar-static-top" style={{marginBottom: 0, height: '80px'}}>

            <div className="navbar-header" style={{'padding':'0px'}}>

                <a className="navbar-minimalize minimalize-styl-2 btn btn-primary " href="#"><i className="fa fa-bars"></i> </a>
                <p className='navbar-brand'>

                    {this.state.userData.cda_ana} {this.state.userData.des_ragsoc}

                    <small>
                        <div>{this.state.userData.des_indir} {this.state.userData.des_loc} {this.state.userData.sig_prov}</div>

                        {this.state.userData.agent && <div style={{'margin-top':'5px'}}>{'Agente '+this.state.userData.agent.id} {this.state.userData.agent.ragsoc} {this.state.userData.cell}</div> }
                    </small>

                </p>

            </div>
            <div className="nav navbar-top-links navbar-right  visible-xs">
                <li>
                    <a onClick={this.onLogout}>
                        <i className="fa fa-sign-out"></i> {t('Logout')}
                    </a>
                </li>
            </div>
            <ul className="nav navbar-top-links navbar-right  hidden-xs">
                <a href='/doc/garanzia.pdf' target="_blank"><li style={{'margin-right':'10px','fontWeight':'bold'}}>GARANZIA ORDINI MONDOPRIMED</li>
                </a>
                <li>
                    <span className="m-r-sm text-muted welcome-message">{t('PRIMED - Customer area')}</span>
                </li>

                <li>
                    <a onClick={this.onLogout}>
                        <i className="fa fa-sign-out"></i> {t('Logout')}
                    </a>
                </li>
            </ul>

        </nav>;

        return <div className={"PrivateArea"}>
            
            <SweetAlert />

            <div style={{display: 'flex'}}>
                <div style={{position: 'relative'}}>
                    <NavigationMenu />
                </div>
                <div className="gray-bg" style={{position: 'relative', flexGrow: 1, minHeight: '100vh'}}>
                    {navContent}
                    {content}
                    <Footer />
                </div>
            </div>

            { /* <NavigationMenu />

            <div id="page-wrapper" className="gray-bg">

            <div className="row border-bottom" style={{height:'85px'}}>

            </div>

            {content}

            <Footer />

            </div>*/ }

        </div>;
    }
}

export default PrivateArea;