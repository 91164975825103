import {Loader, pweb, pwebs, Route} from "./Common";
import t from "./i18n";
import React, {Component, useEffect, useState} from 'react';

class Downloads extends Component {

  constructor(props){
    super(props);

    this.state = {
      ...pweb().store.getState()
    };

    pwebs().subscribe( () => { this.setState( pweb().store.getState() )} );

    this.downloadDirFormRef = React.createRef();
    this.downloadDirPathRef = React.createRef();
  }

  componentDidMount(){

    const loc = window.location.pathname.split('/');

    const labels = [{label: t('Download'), url:'/download'}];

    if( loc.length > 1 )
      labels.push({ label: t(`Section `) + ` ${loc[2] ? decodeURIComponent(loc[2]) : 'Download'}"`});

    pwebs().dispatch( pweb().setHeaderBreadcrumbs( labels ) );

    Route.onLocationChange( () => {
      this.forceUpdate();
    });

  }

  render(){
    var loc = window.location.pathname.split('/')
      .map( s => { return decodeURIComponent( s ) } )
      .filter( i => { return i.length > 0 });

    if( !this.state.downloads )
      return <Loader />;

    var downloads = this.state.downloads;

    loc = loc.slice( loc.indexOf( 'downloads' )+1 );

    for( let k in loc ){
      if( !downloads[loc[k]] )
        break;
      downloads = downloads[loc[k]];
    }

    const isAdmin = this.state.userData && this.state.userData.is_admin;

    if( !isNaN( parseInt( Object.keys( downloads )[0] ) ) ){
      for( let k in downloads ){
        if( downloads[k].cda_nome == loc[loc.length-1] ){
          setTimeout( () => {
            if( document.getElementById('download_form') ){
              document.getElementById('download_form').submit();
              Route.pushLocation( `downloads/${loc.slice(0,loc.length-2).join('/')}`)
            }
          } , 2000 );

          return <div>
            <form id="download_form" target="" method="post" action={`${pweb().apiUrl()}/download/get/${downloads[k].cda_nome}`} >
              <input type="hidden" name="access_token" value={pwebs().getState().authorizationKey} />
            </form>
            <Loader />
          </div>;
        }
      }
    }

    return <React.Fragment>

      <form id="download_dir_form" ref={this.downloadDirFormRef}
            target="" method="post" action={`${pweb().apiUrl()}/download/dir`} >
        <input type="hidden" name="access_token" value={pwebs().getState().authorizationKey} />
        <input type="hidden" ref={this.downloadDirPathRef} name="dir" value={loc} />
      </form>

      {Object.keys(downloads).map( key => {

        //const isDir = ! key.match( /^[0-9]+$/i );
        const isDir = !downloads[key]._id;

        const onClick = p => {
          Route.pushLocation( '/downloads/' + loc.join('/') + '/' + p );
        }

        const onDownload = e => {
          if( this.downloadDirFormRef.current ){
            this.downloadDirPathRef.current.value = e;
            this.downloadDirFormRef.current.submit();
          }
        }

        if( isDir ){
          return <div class="file-box">
            <div class="file">
              <a href="#" onClick={ e => {
                e.preventDefault();
                onDownload( loc.join(',') + ',' + key) }}>
                            <span class="corner">
                                <i className="fa fa-download"></i>
                            </span>
              </a>

              <a href="#" onClick={ e => { e.preventDefault(); onClick( key ) }}>
                <div class="icon">
                  <i class="fa fa-folder"></i>
                </div>
              </a>
              <a href="#" onClick={ e => { e.preventDefault(); onClick( key ) }}>
                <div class="file-name">{key}<br/></div>
              </a>
            </div>

          </div>;
        }

        if( !isDir ){
          return <DownloadItem isAdmin={isAdmin} item={{...downloads[key], link:`/downloads/${loc.join('/')}/${downloads[key].cda_nome}`}} />;
        }

        return <p></p>;
      })}

      <div style={{clear: 'left'}}></div>

    </React.Fragment>

  }
}


function DownloadItem(props){
  const item = props.item;

  const target = isNaN( parseInt( Object.keys( item )[0] ) ) ? '_blank' : '';

  return <div class="file-box">
    <div class="file">

      {props.isAdmin && <a className={"btn btn-primary"} href={"/system/downloads_edit/"+item._id}><i className={"fa fa-edit"}></i></a>}

      <a target={target} href={item.link}>
        <span class="corner"></span>

        <div class="icon">
          <i class="fa fa-file"></i>
        </div>
        <div class="file-name">
          {item.cda_nome}
          <br/>

        </div>
      </a>
    </div>

  </div>
}

export { Downloads };